<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <table-box ref="tableBox" :tableTitle="'积分提现审核'" :tableFieldNum="tableFieldNum" :action="action" :params="queryParams" :reloadData.sync="reloadData">
        <template #title-right>
          <div class="search-div">
            <label>
              <select v-model="searchType">
                <option value="1">用户账号</option>
                <option value="2">用户昵称</option>
              </select>
            </label>
            <label>
              <input class="search-input" type="text" maxlength="20" v-model="searchMsg" placeholder="搜索相关数据..." />
            </label>
            <input class="search-btn" type="button" value="搜索" @click="onSearch" />
          </div>
        </template>
        <template #table-head>
          <th v-for="(item, index) in tableField" v-show="item.state" :key="index">{{ item.alias }}</th>
          <th v-if="isOperation" class="operation-th">操作</th>
        </template>
        <template #table-body="props">
          <tr>
            <template v-for="(item, index) in tableField">
              <td v-if="item.isHtml" :key="index" v-show="item.state" v-html="item.value(props.item, item.name)" />
              <td v-else :key="index" v-show="item.state">{{ item.value(props.item, item.name) }}</td>
            </template>
            <td v-if="isOperation">
              <div class="operation-btn-div">
                <template v-if="props.item.status === 0">
                  <span v-if="$validateAuthority(3400000,3402000,3402002)" class="cursor-el" @click="onRefuse(props.item)">拒绝</span>
                  <span v-if="$validateAuthority(3400000,3402000,3402001)" class="cursor-el" @click="onAgree(props.item)">同意</span>
                </template>
              </div>
            </td>
          </tr>
        </template>
      </table-box>
    </div>
  </div>
</template>

<script>
import TableBox from "../../components/TableBox";
import { getTransferDetailItems, postTransferRefuse, postTransferAgree } from "../../common/api";

export default {
  name: "TransferDetails",
  components: { TableBox },
  data() {
    return {
      action: getTransferDetailItems,
      reloadData: false,
      searchType: "1",
      searchMsg: "",
      queryParams: {
        type: 0,
        msg: "",
        pageSize: 20
      },
      tableField: [
        { state: true, alias: "编号", name: "id", title: "id", isHtml: false, value: this.getObjValue },
        { state: true, alias: "批次编号", name: "batchId", title: "batchId", isHtml: false, value: this.getObjValue },
        { state: true, alias: "用户账号", name: "acc", title: "acc", isHtml: false, value: this.getObjValue },
        { state: true, alias: "用户昵称", name: "nickname", title: "nickname", isHtml: false, value: this.getObjValue },
        { state: true, alias: "用户类型", name: "userType", title: "userType", isHtml: false, value: this.getObjValue },
        { state: true, alias: "使用积分", name: "score", title: "score", isHtml: false, value: this.getObjValue },
        { state: true, alias: "转账金额", name: "amount", title: "amount", isHtml: false, value: this.getObjValue },
        { state: true, alias: "转账状态", name: "status", title: "status", isHtml: false, value: this.getObjValue },
        { state: true, alias: "备注", name: "remark", title: "remark", isHtml: false, value: this.getObjValue },
        { state: true, alias: "操作人", name: "managerName", title: "managerName", isHtml: false, value: this.getObjValue },
        { state: true, alias: "创建时间", name: "createTime", title: "createTime", isHtml: true, value: this.getObjValue }
      ]
    };
  },
  computed: {
    isOperation() {
      return this.$hasAuthority(3400000,3402000,[3402001,3402002]);
    },
    tableFieldNum() {
      let num = this.isOperation ? 1 : 0;
      this.tableField.forEach(function(value) {
        if (value.state) {
          ++num;
        }
      });
      return num;
    }
  },
  methods: {
    getObjValue(item, field) {
      if(field === "status"){
        switch (item[field]) {
          case 0: return "待确认";
          case 1: return "待转账";
          case 2: return "拒绝提现";
          case 3: return "已提交";
          case 4: return "转账中";
          case 5: return "转账成功";
          case 6: return "转账失败";
          default: return "未知";
        }
      }else if(field === "remark"){
        return item.status === 0 ? "" : item[field];
      }else{
        return item[field];
      }
    },
    onReloadData() {
      this.reloadData = true;
    },
    onSearch() {
      this.queryParams.type = this.searchType;
      this.queryParams.msg = this.searchMsg.trim();
    },
    onRefuse(item) {
      this.$layer.prompt(
          {
            formType: 1,
            title: "拒绝提现",
            area: ["450px", "200px"]
          },
          (value, index) => {
            if(value === undefined){
              this.$layer.msg("请输入拒绝理由");
            }else{
              value = value.trim();
              if(value.length < 1){
                this.$layer.msg("请输入拒绝理由");
              }else if(value.length > 32){
                this.$layer.msg("拒绝理由不能超过32个字符");
              }else{
                postTransferRefuse({ id: item.id, remark: value })
                  .then(() => {
                    this.$layer.msg("操作成功");
                    this.onReloadData();
                    this.$layer.close(index);
                  })
                  .catch(error => {
                    this.$layer.close(index);
                    if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                      this.$layer.msg(error.data.msg);
                    } else {
                      this.$layer.msg("操作失败");
                    }
                  });
              }
            }
          }
      );
    },
    onAgree(item) {
      this.$layer.confirm(`是否同意向用户【${item.acc}】转账？`, layerid => {
        postTransferAgree({ id: item.id })
          .then(() => {
            this.$layer.msg("操作成功");
            this.onReloadData();
            this.$layer.close(layerid);
          })
          .catch(error => {
            this.$layer.close(layerid);
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("操作失败");
            }
          });
      });
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 30px 20px 10px 20px;
    .search-div {
      height: 100%;
      display: flex;
      select {
        width: 110px;
        height: 100%;
        border: 1px solid #dcdcdc;
      }
      .state-label {
        margin-left: 5px;
        margin-right: 10px;
      }
      .search-input {
        width: 140px;
        height: 33px;
        border: 1px solid #dcdcdc;
        padding: 0 15px;
        margin-left: 5px;
      }
      .search-btn {
        width: 80px;
        height: 100%;
        color: #f4f6f5;
        border: none;
        background: #4676c8;
      }
      .role-select {
        margin-left: 5px;
      }
    }
    .operation-th {
      width: 80px;
    }
    .operation-btn-div {
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>